// Clients.scss
.clients-section {
  background-color: #000;
  color: #ffffff;
  padding: 3rem 1rem; // Reduced padding for mobile

  @media (min-width: 768px) {
    padding: 4rem 6rem; // Original padding for larger screens
  }
  
  h1 {
    font-size: 2rem; // Smaller font size for mobile
    margin-bottom: 1.5rem;
    position: relative;
    display: block;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 2.5rem; // Original font size for larger screens
      margin-bottom: 2rem;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 80px; // Slightly smaller width for mobile
      height: 3px;
      background: #ffa500;

      @media (min-width: 768px) {
        width: 100px; // Original width for larger screens
      }
    }
  }

  h1, .intro {
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .intro {
    margin: 0 auto 2rem;
    line-height: 1.4;
    font-size: 1rem;
    max-width: 100%;
    text-align: center;

    @media (min-width: 768px) {
      margin: 0 auto 3rem;
      line-height: 1.6;
      font-size: 1.1rem;
      max-width: 800px;
    }
  }
}

.client-logo {
  margin: 0 1rem; // Reduced margin for mobile
  padding: 5px; // Reduced padding for mobile
  width: 80px; // Smaller width for mobile
  text-align: center;
  transition: transform 0.3s ease, opacity 0.3s ease;

  @media (min-width: 768px) {
    margin: 0 2.2rem;
    padding: 10px;
    width: 120px;

    &:hover {
      transform: scale(1.1);
    }
  }

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.marquee {
  padding: 0.5rem 0; // Reduced padding for mobile
  display: flex;
  margin-bottom: 1rem; // Reduced margin for mobile

  @media (min-width: 768px) {
    padding: 1rem 0;
    margin-bottom: 2rem;

    &:hover {
      animation-play-state: paused;
    }
  }
}

.marquee-container {
  overflow: hidden;
}

.marquee-content {
  display: flex;
}