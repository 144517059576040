.footer-content {
    text-align: center;
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 1px solid #333;
  
    .footer-logo {
      font-size: 2rem;
      font-weight: bold;
      color: #ffa500;
      margin-bottom: 1rem;
    }
    .credits {
        margin-top: 10px;
        font-size: 0.9em;
        color: #888;
    
        a {
          color: #ffa500;
          text-decoration: none;
          transition: color 0.3s ease;
    
          &:hover {
            color: #fff;
          }
        }
      }
    p {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  
    .copyright {
      font-size: 1rem;
      color: #999;
    }
  }
  
  @media (max-width: 768px) {
    .footer-content {
      .footer-logo {
        font-size: 1.8rem;
      }
  
      p {
        font-size: 1rem;
      }
  
      .copyright {
        font-size: 0.9rem;
      }
    }
  }