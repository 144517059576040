.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 2rem;
  background-color: #000;
  color: #fff;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: 1000;

  .logo {
    display: flex;
    align-items: center;
    .logo-image {
      width: 80px;
      height: 80px;
      margin-right: 0.5rem;
      
    }

    .logo-text {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .nav {
    ul {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0 1rem;

        a {
          color: #fff;
          text-decoration: none;
          transition: color 0.3s ease;
          font-size: 1rem;

          &:hover {
            color: #ccc;
          }
        }
      }

      .contact-us-item {
        a {
          background-color: #FFA500;
          color: #000;
          padding: 0.5rem 1rem;
          border-radius: 4px;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #ff9100;
            color: #000;
          }
        }
      }
    }
  }

  .hamburger {
    display: none;
  }

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;

    .logo {
      .logo-image {
        width: 60px;
        height: 60px;
      }

      .logo-text {
        font-size: 0.9rem;
        white-space: normal;
        overflow: visible;
        text-overflow: clip;
        max-width: none;
        line-height: 1.2;
      }
    }

    .nav {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.9);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transform: translateY(-100%);
      transition: transform 0.3s ease-in-out;
      z-index: 1001;

      &.open {
        transform: translateY(0);
      }

      ul {
        flex-direction: column;
        align-items: center;

        li {
          margin: 1rem 0;

          a {
            font-size: 1.5rem;
          }
        }

        .contact-us-item {
          margin-top: 2rem;
          
          a {
            font-size: 1.5rem;
            padding: 0.75rem 1.5rem;
          }
        }
      }
    }

    .hamburger {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 30px;
      height: 25px;
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
      z-index: 1002;

      span {
        width: 30px;
        height: 3px;
        background: #fff;
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
      }

      &.open {
        span {
          &:first-child {
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}