.contact-us {
  background-color: #000;
  color: #ffffff;
  padding: 4rem 1rem;
  overflow-x: hidden;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    position: relative;
    display: block;
    text-align: center;

    &:after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 100px;
      height: 3px;
      background: #ffa500;
    }
  }

  .intro-text {
    text-align: center;
    max-width: 800px;
    margin: 0 auto 3rem;
    font-size: 1.1rem;
    line-height: 1.6;
  }

  .contact-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .contact-info {
    flex: 1 1 300px;
    margin-bottom: 2rem;
  }

  .contact-details {
    margin-bottom: 2rem;

    .contact-item {
      margin-bottom: 1.5rem;

      h3 {
        font-size: 1.5rem;
        color: #ffa500;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 1.2rem;
        line-height: 1.4;
        word-wrap: break-word;
      }
    }
  }

  .quick-links {
    flex: 1 1 300px;
    margin-bottom: 2rem;

    h3 {
      font-size: 1.5rem;
      color: #ffa500;
      margin-bottom: 1rem;
    }

    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin-bottom: 0.5rem;

        a {
          color: #ffffff;
          text-decoration: none;
          transition: color 0.3s ease;
          font-size: 1.2rem;

          &:hover {
            color: #ffa500;
          }
        }
      }
    }
  }

  .contact-form {
    flex: 1 1 400px;

    h2 {
      font-size: 1.5rem;
      color: #ffa500;
      margin-bottom: 1rem;
    }

    form {
      display: flex;
      flex-direction: column;

      .form-group {
        margin-bottom: 1rem;

        label {
          display: block;
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }

        input, textarea {
          width: 100%;
          padding: 0.75rem;
          font-size: 1rem;
          background-color: #1a1a1a;
          color: #ffffff;
          border: 1px solid #333;
          border-radius: 4px;
        }

        textarea {
          resize: vertical;
          min-height: 100px;
        }
      }

      button {
        align-self: flex-start;
        padding: 0.75rem 1.5rem;
        font-size: 1rem;
        color: #000;
        background-color: #ffa500;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #ff8c00;
        }
      }
    }
  }

  .success-message, .error-message {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
  }
  
  .success-message {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .error-message {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }

  @media (max-width: 768px) {
    padding: 3rem 0;

    h1 {
      font-size: 2rem;
    }

    .intro-text {
      font-size: 1rem;
      padding: 0 1rem;
      margin-bottom: 2rem;
    }

    .contact-container {
      flex-direction: column;
      align-items: stretch;
      padding: 0 0.5rem;
      > div:not(.contact-form) {
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 10px;
        padding: 1.5rem;
       
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 10px 20px rgba(255, 165, 0, 0.1);
        }
      }
      .quick-links {
        display: none;
      }
    }

    .contact-info,
    .contact-form {
      flex: 1 1 100%;
      max-width: 92%;
      margin: 0 0 2rem 0;
      
    }
   
    .contact-form {
      width: auto;

      h2 {
        font-size: 1.3rem;
      }

      form {
        .form-group {
          input, textarea {
            font-size: 16px;
          }
        }

        button {
          width: 50%;
          padding: 1rem;
        }
      }
    }

    .contact-details {
      .contact-item {
        h3 {
          font-size: 1.3rem;
        }

        p {
          font-size: 1rem;
          word-break: break-word;
        }
      }
    }
  }
}