.our-team {
    background-color: #000;
    color: #fff;
    padding: 4rem 1rem;
    // margin: 3rem 0;
    text-align: center;
  
    h2 {
      font-size: 2.5rem;
      margin-bottom: 3rem;
      position: relative;
      display: inline-block;
  
      &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
        height: 3px;
        background: #ffa500;
      }
    }
    .intro {
        margin: 0 auto 3rem;
        line-height: 1.6;
        font-size: 1.1rem;
        max-width: 800px;
        text-align: center;
      }
  
    .team-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 4rem;
      max-width: 1400px;
      margin: 0 auto;
      &.mobile-view {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        height: auto;
        padding-bottom: 60px; // Add space for the buttons
  
        .team-member {
          width: 100%;
          max-width: 300px;
          margin-bottom: 20px;
        }
  
        .nav-buttons {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          gap: 20px;
          margin-top: 20px;
        }
  
        .nav-button {
          position: static;
          transform: none;
          padding: 10px 15px;
          border-radius: 5px;
        }
      }
    }
  
    .team-member {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      padding: 2rem;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
  
      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 10px 20px rgba(255, 165, 0, 0.2);
      }
  
      .member-image {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        margin: 0 auto 1.5rem;
        overflow: hidden;
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
  
      .member-info {
        h3 {
          font-size: 1.5rem;
          margin-bottom: 0.5rem;
          color: #ffa500;
        }
  
        .position {
          font-size: 1.2rem;
          font-weight: bold;
          margin-bottom: 0.5rem;
        }
  
        .experience, .expertise {
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }
  
        .linkedin-link {
          display: inline-block;
          margin-top: 1rem;
          font-size: 1.5rem;
          color: #0077B5;
          transition: color 0.3s ease, transform 0.3s ease;
        
          &:hover {
            color: #00A0DC;
            transform: scale(1.1);
          }
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .our-team {
      padding: 3rem 1rem;
  
      h2 {
        font-size: 2rem;
      }
      .intro {
        font-size: 1rem;
        margin-bottom: 1.5rem;
      }
      .team-grid {
        &.mobile-view {
          .team-member {
            max-width: 250px;
          }
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    .our-team {
      padding: 3rem 0.5rem;
  
      h2 {
        font-size: 1.8rem;
      }
  
      .team-member {
        padding: 1.5rem;
  
        .member-image {
          width: 120px;
          height: 120px;
        }
  
        .member-info {
          h3 {
            font-size: 1.3rem;
          }
  
          .position {
            font-size: 1.1rem;
          }
  
          .experience, .expertise {
            font-size: 0.9rem;
          }
        }
      }
    }
  }