body{
  background-color: #000 ;
}
.about-us {
  background-color: #000;
  color: #ffffff;
  padding: 4rem 2rem;
  position: relative;
  overflow: hidden;
  margin: 1rem 0;

  .background-watermark {
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; // Adjust this to change the size of the watermark
    height: 80%; // Adjust this to change the size of the watermark
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.17; // Adjust this to change the watermark opacity
    z-index: 1;
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
    position: relative;
    z-index: 2;

    &:after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 100px;
      height: 3px;
      background: #ffa500;
    }
  }

  .about-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }

  .main-content {
    flex: 0 0 55%;
    padding-right: 2rem;

    p {
      margin-bottom: 2rem;
      line-height: 1.8;
      font-size: 1.1rem;
      text-align: justify;
    }
  }

  .expertise-section {
    flex: 0 0 40%;
    background-color: rgba(255, 165, 0, 0.1); // Reddish-orange background
    border-radius: 10px;
    padding: 2rem;

    h2 {
      font-size: 1.8rem;
      margin-bottom: 1.5rem;
      color: #ffa500;
      text-align: center;
    }

    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        font-size: 1.1rem;

        .icon {
          color: #ffa500;
          margin-right: 1rem;
          font-size: 1.5rem;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .about-us {
    .about-content {
      flex-direction: column;
    }
    .background-watermark {
     top:35%;
      
    }
    .main-content,
    .expertise-section {
      flex: 0 0 100%;
      padding-right: 0;
      margin-bottom: 2rem;
    }
  }
}

@media (max-width: 768px) {
  .about-us {
    padding: 3rem 1rem;

    h1 {
      font-size: 2rem;
    }

    .main-content {
      p {
        font-size: 1rem;
      }
    }

    .expertise-section {
      padding: 1.5rem;

      h2 {
        font-size: 1.5rem;
      }

      ul li {
        font-size: 1rem;

        .icon {
          font-size: 1.3rem;
        }
      }
    }
  }
}