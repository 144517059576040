.carousel-container {
    width: 100%;
    height: 300px;
    margin: 5rem auto 0;
    position: relative;
    overflow: hidden;

    @media (min-width: 1024px) {
        height: 500px;
    }
}

.carousel-slide {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: filter 0.5s ease-in-out;
    filter: grayscale(100%);

    &:hover {
        filter: grayscale(0%);
    }
}

.quote-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    
    @media (min-width: 1024px) {
        padding: 20px;
    }

    p {
        font-size: 12px;
        line-height: 1.3;
        margin: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 100%;
        max-width: 100%;
        word-wrap: break-word;

        @media (min-width: 375px) {
            font-size: 14px;
            -webkit-line-clamp: 3;
        }

        @media (min-width: 1024px) {
            font-size: 24px;
            line-height: 1.4;
            -webkit-line-clamp: initial;
        }
    }
}

.carousel-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.5s ease;
    z-index: 10;
    font-size: 18px;

    &:hover {
        background-color: rgba(0, 0, 0, 0.7);
    }

    &.prev {
        left: 10px;
    }

    &.next {
        right: 10px;
    }

    @media (min-width: 1024px) {
        padding: 15px;
        font-size: 24px;

        &.prev {
            left: 20px;
        }

        &.next {
            right: 20px;
        }
    }
}