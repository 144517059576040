.our-services {
  background-color: #000;
  color: #ffffff;
  padding: 3rem 2rem;
  margin: 1rem 0;
  transition: transform 0.3s ease, box-shadow 0.3s ease, margin 0.3s ease;
  

 
  h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 100px;
      height: 3px;
      background: #ffa500;
    }
  }

  .intro {
    margin: 0 auto 3rem;
    line-height: 1.6;
    font-size: 1.1rem;
    max-width: 800px;
    text-align: center;
  }

  .services-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    margin: 0 auto;
    max-width: 1400px;
    &.mobile-view {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 400px; // Adjust as needed
    }

    .service-item {
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 10px;
      padding: 2rem;
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(255, 165, 0, 0.1);
      }

      .icon {
        font-size: 3rem;
        color: #ffa500;
        margin-bottom: 1rem;
        text-align: center;
      }

      h3 {
        font-size: 1.5rem;
        color: #ffa500;
        margin-bottom: 1rem;
        text-align: center;
      }

      p {
        font-size: 1rem;
        line-height: 1.6;
        text-align: center;
      }
    }
    .nav-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: rgba(255, 165, 0, 0.3);
      border: none;
      color: #fff;
      font-size: 1.5rem;
      padding: 0.5rem;
      cursor: pointer;
      transition: background 0.3s ease;

      &:hover {
        background: rgba(255, 165, 0, 0.5);
      }

      &.prev {
        left: 0;
      }

      &.next {
        right: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .our-services {
    padding: 3rem 1rem;
    &:hover{
      margin:0;
  }
    h1 {
      font-size: 2rem;
    }

    .intro {
      font-size: 1rem;
      margin:0;
    }

    .services-list {
      grid-template-columns: 1fr;
      &.mobile-view {
        .service-item {
          width: 80%; // Adjust as needed
          margin: 0 auto;
        }
      }
      .service-item {
        padding: 1.5rem;

        .icon {
          font-size: 2.5rem;
        }

        h3 {
          font-size: 1.3rem;
        }

        p {
          font-size: 0.9rem;
        }
      }
    }
  }
}